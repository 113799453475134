export class Comment {
    id: number;
    bug_id:number;
    reporter_id:number;
    modified_by_id:number;
    bugnote_text_id:number;
    note_attr:string;
    view_state:number;
    note_type:number;
    time_tracking:number;
    last_modified:number;
    date_submitted: string;
    documented: string;
}