import { User } from './User';

export class Role {
    id: number;
    name: string;
    display_name: string;
    description: string;
    created_date: number;
    updated_date: number;
    users: User[];
}