export class User {
    id: number;
    email: string;
    username: string;
    password: string;
    first_name: string;
    middle_initial: string;
    last_name: string;
    realname: string;    
    token?: string;
    avatar: string;
    enabled: string;
    group_role: [];
}