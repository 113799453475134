export class IssueType {
    id: number;
    project_id: number;
    user_id: number;
    name: string;
    description: string;
    avatar: string;
    default_issue_type: number;
    status: number;
    global_issue_type:number;
}

