export class AccountPref {
    default_project: number;
    email_on_new: number;
    email_on_assigned: number;
    email_on_feedback: number;
    email_on_resolved: number;
    email_on_closed: number;
    email_on_reopened: number;
    email_on_bugnote: number;
    email_on_priority: number;
    timezone: string;
}