export class Project {
    id: number;
    name: string;
    key: string;
    avatar: string;
    status: number;
    enabled: number;
	view_state: number;
	access_min: number;
	file_path: string;
	description: string;
	category_id: string;
	inherit_global: boolean;
}