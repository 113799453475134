export class Issue {
    id: number;
    project_id:number;
    reporter_id:number;
    issue_type_id:number;
    category_id:number;
    subject:string;
    handler_id:number;
    status_id:number;
    duplicate_id:number;
    resolution:number;
    name: string;
    description: string;
}